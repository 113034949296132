import api from '@/services'
import * as actionTypes from './constants'

const { getHomeNewsList } = api

export const changeHomeNewsList = (data) => ({
  type: actionTypes.CHANGE_HOME_NEWS_LIST,
  data,
})

export const getHomeNews = () => async (dispatch) => {
  try {
    const res = await getHomeNewsList()
    dispatch(changeHomeNewsList(res.data))
  } catch (err) {
    console.log(err)
  }
}
