import React from 'react'
import PropTypes from 'prop-types'
import Banner from '@/components/Banner'
import { useSelector } from 'react-redux'
import { isWXBrowser } from '@/utils'
import { NotificationFilled } from '@ant-design/icons'
import { useHistory } from 'react-router'
import NewsIntro from './components/NewsIntro'
import BottomNavigator from './components/BottomNavigator'

import styles from './style.module.scss'

function Home() {
  const history = useHistory()

  const bannerList = [
    {
      tabTitle: '111',
      type: 'video',
      url: 'https://tc-cdn.123u.com/official-website/home/home_video_v5.mp4',
      poster: 'https://tc-cdn.123u.com/official-website/home/poster_v5.png',
      content: (
        <div className={styles.Slogan}>
          <div className={styles.SloganChinese}>让更多人发现人生的美好</div>
          <div className={styles.SloganEnglish}>
            Empower more people to see the beauty in life
          </div>
        </div>
      ),
    },
  ]
  const mobileBannerList = [
    {
      tabTitle: '英雄杀小游戏',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/mobile/home/banner.jpg',
    },
  ]

  const menuList = useSelector((state) => state.common.menuList)

  const testIsWxBrowser = isWXBrowser()

  const gameList = [
    {
      tabTitle: 'RO仙境传说：爱如初见',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/ro_v2.jpg',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/ro.jpg',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>经典还原，正统续作</div>
          <div className={styles.SubTitle}>
            还原经典，美术再升级，丰富职业选择与高自由度流派搭配。
            <br />
            上千种华丽时装任你随心穿搭，冒险也要有时尚。
            <br />
            全能萌宠陪你冒险，一起打怪闯关，给你想要的安全感。
            <br />
            四大赛事谁与争锋，同场竞技至高荣耀。
          </div>
          {/* <div onClick={() => window.open('https://yxsm.qq.com/')} className={styles.DetailBtn}>查看详情</div> */}
        </div>
      ),
    },
    {
      tabTitle: '英雄杀小游戏',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/yxs.png',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/yxs.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>策略卡牌，公平竞技</div>
          <div className={styles.SubTitle}>
            英雄杀小游戏是一款结合了历史人物特色的竞技卡牌游戏。在英雄杀小游戏里，玩家通过选择不同的角色，根据不同角色的技能组合搭配随机拿到的手牌进行战斗，并根据场上的状况及时变更对战策略，才能赢得最终的胜利。
          </div>
          {/* <div onClick={() => window.open('https://yxsm.qq.com/')} className={styles.DetailBtn}>查看详情</div> */}
        </div>
      ),
    },
    {
      tabTitle: '街机三国',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/jjsg_v2.jpg',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/jjsg.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>唤醒街机格斗的激情</div>
          <div className={styles.SubTitle}>
            游戏中以《三国志》为时代背景，玩家扮演的角色穿越到三国时代，成为天选之子，还原了原著中的人物派系和历史战场，为玩家提供7个职业，并有个人争霸，军团争霸，三国争霸等多项赛事活动。
          </div>
          <div
            onClick={() => window.open('http://sg.huanle.com/')}
            className={styles.DetailBtn}
          >
            查看详情
          </div>
        </div>
      ),
    },
    {
      tabTitle: '美食大战老鼠',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/ms.png',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/mobile/product/ms.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>十年易逝，经典不老</div>
          <div className={styles.SubTitle}>
            《美食大战老鼠》是一款极富策略性的网页休闲游戏。贪婪的老鼠结队前来掠夺美食，唯一的防御方式就是培育美食进行反击。游戏集成了角色成长、即时战略、塔防战和卡片收集等要素，乐趣十足。
          </div>
          <div
            onClick={() => window.open('http://my.4399.com/yxmsdzls/')}
            className={styles.DetailBtn}
          >
            查看详情
          </div>
        </div>
      ),
    },
    {
      tabTitle: '龙之谷手游',
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/product/dn-banner.png',
      mobileUrl:
        'https://tc-cdn.123u.com/official-website/product/dn-banner-small.png',
      content: (
        <div className={`${styles.Description}`}>
          <div className={styles.Title}>无差别，真竞技</div>
          <div className={styles.SubTitle}>
            龙之谷是一款3D无锁定动作手游，具有酣畅自由的战斗体验，原汁原味的经典形象，刻骨铭心的泪点剧情等特点。龙之谷手游力求100%还原端游特色，将精彩绝伦的战斗于掌中呈现，让玩家的指尖踏上非同凡响的阿尔特里亚之旅。
          </div>
        </div>
      ),
    },
  ]

  const workSpacePic = [
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner1.jpg',
      contentPosition: 'bottom-left',
    },
    {
      type: 'image',
      url: 'https://tc-cdn.123u.com/official-website/recruit/bottombanner2.jpg',
      contentPosition: 'bottom-right',
    },
  ]

  return (
    <>
      {!testIsWxBrowser && (
        <div className={styles.PCBanner}>
          <Banner bannerList={bannerList} />
        </div>
      )}
      <div className={styles.MobileBanner}>
        <Banner bannerList={mobileBannerList} />
      </div>
      <BottomNavigator menuList={menuList} accordion />
      <div className={styles.WorkSpace}>
        <div className={styles.WorkSpaceTitle}>工作在这里</div>
        <p></p>
        <Banner bannerList={workSpacePic} autoplay loop />
      </div>
    </>
  )
}

Home.propTypes = {
  route: PropTypes.shape({ routes: PropTypes.arrayOf(PropTypes.object) })
    .isRequired,
}

export default React.memo(Home)
