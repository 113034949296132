import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import PageTitle from '@/components/PageTitle'
import styles from './style.module.scss'
import NewsCard from './NewsCard'
import useFetchNews from '../../hooks/useFetchNews'

function NewsIntro() {
  const [newsList] = useFetchNews()

  return (
    <div className={styles.NewsContainer}>
      <div>
        <PageTitle title="欢乐动态" subTitle="News" />
        <div className={styles.CardWrap}>
          {!!newsList.length &&
            newsList.map((item) => <NewsCard {...item} key={item.id} />)}
          <Link to="/news" className={styles.SeeMore}>
            查看更多
          </Link>
        </div>
      </div>
    </div>
  )
}

export default memo(NewsIntro)
