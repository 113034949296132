import React, { memo } from 'react'
import PageTitle from '@/components/PageTitle'
import styles from './style.module.scss'

function Map(): JSX.Element {
  return (
    <div className={styles.Map}>
      <picture>
        <source
          srcSet="https://tc-cdn.123u.com/official-website/contact/map4.jpg"
          media="(min-width: 956px)"
        />
        <img src="https://tc-cdn.123u.com/official-website/contact/map4.jpg" />
      </picture>
    </div>
  )
}

function Contact(): JSX.Element {
  const infoList = [
    {
      title: '商务合作',
      briefTitle: '商务',
      name: '王先生',
      wechat: 'wanga3t',
      qq: '2993916065',
      email: 'nonowang@123u.com',
    },
    {
      title: '人力资源',
      briefTitle: '人力资源',
      name: '李女士',
      qq: '583619830',
      email: 'qiqili@huanle.com',
    },
  ]

  return (
    <>
      <Map></Map>
      <div className={styles.ContactContainer}>
        <div className={styles.PCInfo}>
          <div style={{ width: '100%' }}>
            <PageTitle title="联系我们" subTitle="Contact Us" />
          </div>
          <div className={styles.CardWrap}>
            {infoList.map((info) => (
              <div className={styles.Item} key={info.name}>
                <div className={styles.ItemTitle}>
                  {/* <img src="https://tc-cdn.123u.com/official-website/contact/logo.png" /> */}
                  <div className={styles.VerticalLine}></div>
                  {info.title}
                </div>
                <div className={styles.ItemContentWrap}>
                  <div className={styles.ItemContent}>
                    <div>{info.name}</div>
                    {info.wechat && <div>微信：{info.wechat}</div>}
                    <div>QQ：{info.qq}</div>
                    <div>邮箱：{info.email}</div>
                  </div>
                </div>
              </div>
            ))}
            <div className={styles.QrCode}>
              <div className={styles.Row}>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/official-site-v2.jpg"></img>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/video-v2.jpg"></img>
              </div>
              <div className={styles.Row}>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/wechat-public-v2.jpg"></img>
                <img src="https://tc-cdn.123u.com/official-website/qrcode/joy-club-v2.jpg"></img>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.MobileInfo}>
          {infoList.map((info) => (
            <div className={styles.Info} key={info.name}>
              <div className={styles.Left}>{info.briefTitle}</div>
              <div className={styles.Right}>
                <div>{info.name}</div>
                {info.wechat && <div>微信：{info.wechat}</div>}
                <div>QQ：{info.qq}</div>
                <div>邮箱：{info.email}</div>
              </div>
            </div>
          ))}
          <div className={styles.QrCode}>
            <div className={styles.Row}>
              <img src="https://tc-cdn.123u.com/official-website/qrcode/official-site-v2.jpg"></img>
              <img src="https://tc-cdn.123u.com/official-website/qrcode/video-v2.jpg"></img>
            </div>
            <div className={styles.Row}>
              <img src="https://tc-cdn.123u.com/official-website/qrcode/wechat-public-v2.jpg"></img>
              <img src="https://tc-cdn.123u.com/official-website/qrcode/joy-club-v2.jpg"></img>
            </div>
            <div className={styles.QrCodeTip}>
              长按保存至相册后打开对应App扫码
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(Contact)
